"use client"

import React, { FormEvent, useState } from "react"
import { Input } from "@/components/ui/input"
import { signIn } from "next-auth/react"
import { Button } from "@/components/ui/button"
import { toast } from "sonner"

const MagicLinkGenerator = () => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      const formData = new FormData(event.currentTarget)
      const email = formData.get("email")
      if (!email || typeof email !== "string") {
        throw new Error('Email invalide')
      }

      const isRegistered = await fetch(`/api/user/${encodeURIComponent(email)}`)
      if (isRegistered.status === 404) {
        throw new Error("Vous n'avez pas de compte, pour en obtenir un, veuillez contacter l'administrateur")
      }
      const result = await signIn("email", { email })

      if (result?.error) {
        throw new Error(result.error)
      }

      toast.success("Un lien de connexion a été envoyé à votre adresse email")

    } catch (error) {
      const typedError = error as Error
      console.error("🔴 Error during login:", typedError.message)
      toast.error(typedError.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2">
      <Input type="email" name="email" placeholder="Email" />
      <Button loading={isLoading} type="submit">Envoyer un lien de connexion par email</Button>
    </form>
  )
}

export default MagicLinkGenerator